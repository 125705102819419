<template>
  <div class="lnb">
    <div class="lnbLogo">
      <router-link to="/admin/datamanage">
        <img src="@/assets/images/logo.png" alt="logo" />
      </router-link>
    </div>

    <el-row class="tac mainMenu">
      <el-col :span="12">
        <el-menu class="el-menu-vertical-demo" :default-active="navbarState">
          <el-menu-item index="-1">
            <router-link to="/admin/dashboard">
              <span
                ><img
                  src="@/assets/images/icon_svg/LNB_dashboard.svg"
                  alt="icon"
                  class="메타데이터"
                />Youtube 대시보드</span
              >
            </router-link>
          </el-menu-item>
          <el-menu-item index="0">
            <router-link to="/admin/datamanage">
              <span
                ><img
                  src="@/assets/images/icon_svg/LNB_metadata.svg"
                  alt="icon"
                  class="메타데이터"
                />메타데이터
              </span>
            </router-link>
          </el-menu-item>

          <el-submenu index="1" v-if="isAdmin == true">
            <template slot="title">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_account.svg"
                  alt="icon"
                  class="계정관리"
                />
                계정관리
              </span>
            </template>
            <el-menu-item index="1-1"
              ><router-link to="/admin/usermanage">
                <span>계정정보 관리</span>
              </router-link></el-menu-item
            >
            <el-menu-item index="1-2"
              ><router-link to="/admin/teammanage">
                <span>팀관리</span>
              </router-link></el-menu-item
            >
          </el-submenu>

          <el-menu-item index="2">
            <router-link to="/admin/mypage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_mypage.svg"
                  alt="icon"
                  class="마이페이지"
                />
                마이페이지
              </span>
            </router-link>
          </el-menu-item>

          <el-menu-item index="9">
            <router-link to @click.native="logout">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_logout.svg"
                  alt="icon"
                  class="로그아웃"
                />
                로그아웃
              </span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isActive: false,
      isDropped: false,
    };
  },
  computed: {
    ...mapState(["navbarState", "isAdmin"]),
  },

  methods: {
    logout() {
      let result = confirm("로그아웃 하시겠습니까?");
      if (result == false) {
        return;
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
